import React from 'react';
import styled from 'styled-components';
import NewsLetterCTA from '../components/NewsLetterCTA';
import SEO from '../components/SEO';

import '../assets/style/utilities/wrapper.css';
import '../assets/style/font.css';

const AboutPageStyles = styled.div`
  padding-bottom: 5rem;
  /* border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: ${({ theme }) => theme.color.secondary}; */

  h2 {
    margin-bottom: 0.5rem;
  }

  p {
    font-weight: ${({ theme }) => theme.font.weight.light};
    margin-bottom: 1.25rem;
  }
`;

export default function AboutPage(): JSX.Element {
  return (
    <>
      <SEO title={`À propos`} />
      <AboutPageStyles>
        <h1 className="page-title">À propos</h1>
        <p>Je m’appelle Elisabeth Pastor. J’ai créé ce blog pour partager mes publications.</p>
        <p>
          Quand je rédige et prépare mes « livres », mes « recueils » et mes documents divers, je sais qu’aucune maison d’édition existante
          ne les publiera jamais. Alors, pour pouvoir les partager quand même avec un public ami, j’ai créé ce blog qui me sert de maison
          d’édition: Les éditions Simplissi-mots.
        </p>
        <p>
          Ainsi je suis devenue autrice et éditrice de la même maison. Une autrice qui peut écrire et travailler avec passion sur tous ses
          thèmes favoris, sans contraintes éditoriales. Une éditrice bienveillante et sans prétention quant à ses publications. À vous
          maintenant, chères lectrices et chers lecteurs, d’aller fouiller sur les rayons de la bibliothèque et dans le stock de ma maison
          d’édition. J’espère que vous y trouverez, si ce n’est de la grande littérature, du moins de l’intérêt et du plaisir.
        </p>

        <h2>Entreprise « Au plus simple »</h2>
        <p>
          J'ai également mon entreprise d’assistance personnelle: Au plus simple. Si vous avez besoin d’aide pour vous organiser, écrire des
          documents, simplifier des textes, vous installer à Neuchâtel ou d’une manière générale, si vous cherchez à vous simplifier la vie,
          alors faites au plus simple ! Suivez ce lien:{' '}
          <a className="link" href="https://auplussimple.ch/">
            {' '}
            auplussimple.ch{' '}
          </a>
        </p>
        <p>Je vous accueillerai sur mon site « Au plus simple » et serai votre assistante personnelle pour vos projets.</p>
      </AboutPageStyles>
      {/* <NewsLetterCTA></NewsLetterCTA> */}
    </>
  );
}
